<template>
  <v-dialog
    scrollable
    max-width="700"
    persistent
    :value="true"
  >
    <v-card
      v-if="isLoading"
      class="pa-4"
    >
      <v-progress-linear
        indeterminate
        color="primary"
      ></v-progress-linear>
      <h6 class="text-h6 text-center">Carregando...</h6>
    </v-card>
    <v-card v-else>
      <v-card-title class="primary white--text">
        <h5 class="text-h6">Nova atividade CRM</h5>
        <v-spacer />
        <v-btn
          @click="$router.go(-1)"
          icon
          dark
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <CrmForm
          :client="crm.client"
          :crm="crm"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-chip
          v-if="crm.id"
          class="white--text"
          rounded
          :color="getLevelColor(crm.priority_level)"
        >
          {{ crm.priority_level | levelLabel }}
        </v-chip>
        <v-spacer />
        <v-btn
          @click="cancel"
          outlined
          rounded
          color="primary"
          >cancelar</v-btn
        >
        <v-btn
          :disabled="checkDisabledButton"
          @click="updateCRM"
          rounded
          color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CrmForm: () => import('./CrmForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    crm: {},
    isLoading: true,
    askToLeave: true,
  }),
  filters: {
    levelLabel(value) {
      switch (value) {
        case 1:
          return 'Baixa'
        case 2:
          return 'Média'
        case 3:
          return 'Alta'
      }
    },
  },
  methods: {
    getCurrentCrm() {
      this.$api
        .get(`crms/${this.$route.params.id}`)
        .then((res) => {
          this.crm = res.data.data

          this.crm.return_date = this.crm.return_date
            .slice(0, 10)
            .split('-')
            .reverse()
            .join('/')

          this.crm.client.client_contact = JSON.parse(
            this.crm.client.client_contact
          )
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateCRM() {
      this.askToLeave = false
      const convertedDate = this.crm.return_date.split('/').reverse().join('-')
      this.$api
        .patch(`crm/update`, {
          ...this.crm,
          return_date: convertedDate,
          client_id: this.crm.client.id,
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'CRM atualizado com sucesso!',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
              this.$router.go(-1)
            },
          })
        })
        .catch((err) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: err.response.data.message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    cancel() {
      this.$router.go(-1)
    },
    getLevelColor(value) {
      switch (value) {
        case 1:
          return 'blue'
        case 2:
          return 'orange'
        case 3:
          return 'error'
      }
    },
  },
  created() {
    this.getCurrentCrm()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
